<template>
  <el-container class="mars-ape-layout">
    <el-main :class='`${$route.name}_main`'>
      <div style='display: flex; flex-direction: column' :class='`${$route.name}_main-inner`'>
        <router-view :class='`${$route.name}_main-inner__content`' />
      </div>
    </el-main>
  </el-container>
</template>
<script>

import {mapGetters} from 'vuex'

export default {
  name: 'PageLayout',
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    isRootRoutePath: function() {
      return this.$route.path === '/'
    }
  },
}
</script>
<style lang='stylus'>
.mod-index
  border-radius 5px
  background #fff
  padding 20px
  min-height 800px
  line-height 1.5

  p
    margin 0

  h3
    margin-block-start 0
    margin-block-end 0.5em

  .xm-gt img
    height 240px
    margin-left -16px

  .jx-zz img
    height 400px
    margin-top 24px
    margin-right 24px

.el-container
  background #f0f2f5

.el-aside
  height 100%
  max-height 100vh
  overflow-y auto
  box-shadow 2px 0 6px rgba(0, 21, 41, .35)
  position relative
  width auto !important
  z-index 10
  transitionall .3s
  background #001529

.el-header
  height 64px !important
  padding 0
  width 100%

.el-main
  padding 0
  margin 6px 4px
  overflow inherit
  flexnone !important

//min-height 720px

.main-page-content
  border-radius 5px
  background #ffffff

//padding 8px 10px 0 10px

.el-footer
  height auto !important
</style>
<style lang='scss' scoped>
.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  //width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}
</style>
